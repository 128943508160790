import React, { useEffect } from "react";
const ImportScript = (props) => {
  const [scriptLoaded, setScriptLoaded] = React.useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = props.src;
    script.dataset.pageId = props.pageId;
    script.id = "wirewheel-ppp";
    script.dataset.jsonSrc = "https://corteva-uat.wirewheel.io";
    script.async = true;
    script.crossOrigin = "anonymous";

    document.getElementById("wirewheel").appendChild(script);

    script.onload = function () {
      console.log("script loaded");
      const value = window.document.dispatchEvent(
        new Event("DOMContentLoaded", {
          bubbles: true,
        })
      );

      setScriptLoaded(value);
    };
  }, [props]);

  useEffect(() => {
    if (scriptLoaded) {
      console.log("event triggered");
      var handle = setInterval(() => {
        if (document.getElementsByClassName("ww-page-wrapper").length) {
          clearInterval(handle);
          var submitBtn = document.getElementsByClassName(
            "ww-self-service__form-submit"
          )[0];

          submitBtn.setAttribute("value", "Begin Assessment");

          submitBtn.addEventListener("click", function () {
            var showLoader = setInterval(() => {
              if (
                document.getElementsByClassName(
                  "ww-self-service__return-container"
                ).length
              ) {
                clearInterval(showLoader);
                var openRequest = document.getElementsByClassName(
                  "ww-self-service__return-button"
                )[0];

                openRequest.innerHTML = "Open Assessment";
              }
              if (
                document.getElementsByClassName("ww-self-service__success-link")
                  .length
              ) {
                var newRequest = document.getElementsByClassName(
                  "ww-self-service__success-link"
                )[0];
                newRequest.style.display = "none";
              }
            }, 100);
          });
        }
      }, 100);
    }
  }, [scriptLoaded]);

  return <div id="wirewheel" className="container"></div>;
};
export default ImportScript;

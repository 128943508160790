import React from "react";
import SrrHeader from "../shared/components/SrrHeader";
import ImportScript from "../shared/components/ImportScript";

function PrivacyImpactAssessment() {
  const queryString = window.location.pathname.split("/").join("");
  console.log("queryString", queryString);

  return (
    <>
      <SrrHeader heading="Privacy Impact Assessment" />

      <section className="form-section my-3 text-left">
        <ImportScript
          src="https://wirewheel-p3-public-storage-corteva-uat-us-east-1.s3.us-east-1.amazonaws.com/6336b50575235f0009716648/embedded-ppp.js"
          pagId="6336b50575235f0009716648"
          queryString={queryString}
        />
      </section>
    </>
  );
}

export default PrivacyImpactAssessment;

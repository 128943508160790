import React from "react";
import { Navbar, Image } from "react-bootstrap";
import Logo from "../images/logo.png";
import { Container } from "react-bootstrap";

function Header() {
  return (
    <Navbar collapseOnSelect expand="lg" bg="white" variant="light">
      <Container>
        <Navbar.Brand href="https://www.corteva.com/">
          <Image src={Logo} alt="Corteva logo" className="p-2" width="160px" />
        </Navbar.Brand>
        <div className="flexarea"></div>
      </Container>
    </Navbar>
  );
}

export default Header;
